import './style.scss'
import { Button, message, Modal } from 'antd'
import { FC, memo, useEffect, useState } from 'react'
import * as Api from '@/api/home'

interface IProps {
  open: boolean
  onSuccess?: () => void
  onCancel?: () => void
}

const ConvertCouponModal: FC<IProps> = (props) => {
  const { open, onSuccess, onCancel } = props
  const [convertList, setConvertList] = useState<any[]>([])
  const [checkOption, setCheckOption] = useState<any>({})

  useEffect(() => {
    if (open) {
      getList()
    }
  }, [open])

  const getList = async () => {
    const res = await Api.getCouponOptions()
    setConvertList(res?.list || [])
    setCheckOption(res?.list?.[0])
  }

  const toConvert = async () => {
    if (!checkOption.id) {
      message.warning('请选择兑换选项')
    }
    await Api.convertCoupon(checkOption.id)
    onSuccess?.()
  }

  return (
    <>
      <Modal
        title="积分兑换代金券"
        maskClosable={false}
        className={`coupon-convert-modal`}
        onCancel={onCancel}
        width={600}
        open={open}
        footer={null}
      >
        <div className="coupon-convert-content">
          <div className="list">
            {convertList?.map((o) => (
              <div
                className={`list-item ${checkOption?.id === o.id ? 'actived' : ''}`}
                key={o.id}
                onClick={() => setCheckOption(o)}
              >
                <div className="score">{o.title}</div>
                <div className="price">
                  <div className="discount-price">
                    <label className="unit">¥</label>
                    {(o.deduct_amount || 0) / 100}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="desc">
            <div className="desc-title">兑换说明：</div>
            <div className="desc-list">
              <div className="desc-list_item">
                1. 代金券用于购买会员、充值积分时立减 {(checkOption?.deduct_amount || 0) / 100} 元;
              </div>
              <div className="desc-list_item">2. 每次支付仅可使用 1 张代金券;</div>
              <div className="desc-list_item">
                3. 代金券自领取时间起 <label className="red">{checkOption?.expire_days}</label>{' '}
                天内有效，请及时使用，过期作废。
              </div>
            </div>
          </div>

          <div className="pay">
            <div className="name">消耗积分</div>
            <div className="credits">
              <label> {checkOption?.credits}</label>
              <label className="unit">积分</label>
            </div>
          </div>

          <div className="btn">
            <Button type="primary" onClick={toConvert}>
              立即兑换
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default memo(ConvertCouponModal)
