import './style.scss'
import { Button, Modal } from 'antd'
import { Buffer } from 'buffer'
import { forwardRef, memo, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import wavConverter from 'wav-converter'
import * as homeApi from '@/api/home'
import { Play } from '@/assets/svg'
import { PlayGray } from '@/assets/svg/play-gray'
import VideoModal from '@/components/VideoModal'
import { UserStore } from '@/global-states'
import { eventTracking, urlSource } from '@/libs/util'
import { LoadingOutlined, PauseOutlined } from '@ant-design/icons'

interface IProps {
  open: boolean
  group: any
  upgradePlan?: () => void
  onCancle?: () => void
}

const VoiceDetail = forwardRef((props: IProps) => {
  const navigate = useNavigate()
  const { open, group, upgradePlan, onCancle } = props
  const { userPackage } = UserStore
  const [voiceDetail, setVoiceDetail] = useState<any>()
  const [previewInfo, setPreviewInfo] = useState<any>()
  const [previewId, setPreviewId] = useState()
  const [playingId, setPlayingId] = useState()
  const previewIdRef = useRef<any>()
  const audioRef = useRef<any>()
  const [videoPreview, setVideoPreview] = useState<any>()

  useEffect(() => {
    if (open) {
      getDetail()
    }
    return () => {
      closeAudio()
      setPreviewInfo(undefined)
    }
  }, [open])

  useEffect(() => {
    previewIdRef.current = previewId
  }, [previewId])

  const getDetail = async () => {
    const res = await homeApi.getCommunityVoiceDetail(group.id)
    if (res.members?.length) {
      setPreviewInfo({
        ...res.members[0],
        preview_text: res.members[0].preview_text || '现在的一切都是为将来的梦想编织翅膀，让梦想在现实中展翅高飞。'
      })
    }
    setVoiceDetail(res || {})
  }

  const previewTts = async (tts: any, text: string) => {
    setPreviewInfo(tts)
    if (playingId === tts.id) {
      closeAudio()
      return
    } else if (previewId === tts.id) {
      return
    }
    eventTracking('VoicePlay', { group_id: group?.id, tts_id: tts?.id })
    setPreviewId(tts.id)
    setPlayingId(undefined)
    clearAudio()

    try {
      const res = await homeApi.previewCommunityVoice(group?.id, tts.id, {
        text,
        voice_parameters: tts.voice_parameters
      })
      if (previewIdRef.current !== tts.id) {
        return
      }
      setPlayingId(tts.id)

      clearAudio()

      const audio = new Audio()
      audio.src = `data:audio/wav;base64,${wavConverter
        .encodeWav(new Buffer(res.audio_base64, 'base64'), {
          numChannels: 1,
          sampleRate: 16000,
          byteRate: 32_000
        })
        .toString('base64')}`
      audio.play()
      audioRef.current = audio

      audio.addEventListener('ended', function () {
        closeAudio()
      })

      audio.addEventListener('pause', function () {
        closeAudio()
      })
    } catch {
      closeAudio()
    }
  }

  const clearAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause?.()
      audioRef.current.src = ''
    }
  }

  const closeAudio = () => {
    clearAudio()
    setPlayingId(undefined)
    setPreviewId(undefined)
  }

  const toCreateVideo = async () => {
    const voice = voiceDetail?.members?.[0]
    collectVoice()
    if (voice) {
      if (userPackage && (userPackage.current_membership_level || 0) < 20 && voice.tts_level === 20) {
        return vipTip()
      }
      sessionStorage.setItem('bookId', group.id)
      localStorage.setItem('voiceId', voice.id)
      eventTracking('VoiceToCreate', { group_id: group.id })
      navigate(`/create-video`)
    }
  }

  const vipTip = () => {
    Modal.confirm({
      title: '当前会员等级不够，无法使用',
      content: <div>高保真声音是尊享版及以上会员专属功能，请您确认当前会员等级是否匹配</div>,
      okText: '升级会员',
      cancelText: '取消',
      onOk: () => {
        upgradePlan?.()
      }
    })
  }

  const collectVoice = async () => {
    await homeApi.updateBookmarkedCommunityVoice({
      community_tts_voice_id: group.id
    })
    getDetail()
  }

  const cancelCollectVoice = async () => {
    eventTracking('VoiceCancleFavor')
    await homeApi.delteBookmarkedCommunityVoice(group.id)
    getDetail()
  }

  return (
    <Modal
      className="commom-modal voice-market-modal"
      open={open}
      footer={null}
      title={null}
      onCancel={onCancle}
      width={1000}
    >
      <div className="voice-market-detail-wrapper">
        <div className="header">
          <div className="header-left">
            <div className="img">
              <img src={group?.cover_url} />
            </div>
            <div className="detail">
              <div className="name">{group?.title}</div>
              {group?.scenes && <div className="desc">适合场景：{group?.scenes?.join('、')}</div>}
            </div>
          </div>
        </div>
        <div className="voice-detail">
          <div className="preview-text">{previewInfo?.preview_text}</div>
          <div className="voice-detail__list">
            {voiceDetail?.members?.map((m: any) => (
              <div key={m.id} className="voice-detail__list__item">
                <div className="img" onClick={() => previewTts(m, m.preview_text)}>
                  {m.cover_url ? (
                    <img src={m.cover_url} />
                  ) : (
                    <div className="list-bg" style={{ background: m.color || '#f1806a' }}>
                      {m.title.substring(0, 1)}
                    </div>
                  )}

                  <div
                    className="op"
                    style={{
                      backgroundColor: (previewId || playingId) === m.id ? 'rgb(0 0 0 / 70%)' : 'transparent'
                    }}
                  >
                    {previewId === m.id ? (
                      playingId === m.id ? (
                        <PauseOutlined />
                      ) : (
                        <LoadingOutlined />
                      )
                    ) : (
                      <PlayGray className="play" />
                    )}
                  </div>
                </div>
                <div className="name ellipsis" title={m.title}>
                  {m.title}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="operate">
          <Button type="primary" onClick={toCreateVideo}>
            去创作
          </Button>
          {voiceDetail?.bookmarked_by_user ? (
            <Button color="primary" variant="dashed" onClick={cancelCollectVoice}>
              取消收藏
            </Button>
          ) : (
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                eventTracking('VoiceFavor')
                collectVoice()
              }}
            >
              添加至我的收藏
            </Button>
          )}
        </div>

        {!!voiceDetail?.demos?.length && (
          <div className="video-wrapper">
            <div className="title">作品展示</div>
            <div className="video-list">
              {voiceDetail?.demos?.map((d: any) => (
                <div key={d.id} className="video-list__item">
                  <div
                    className="img"
                    onClick={() => {
                      setVideoPreview({
                        title: d.name,
                        url: d.url
                      })
                    }}
                  >
                    <div
                      className="bg"
                      style={{
                        backgroundImage: `url(${urlSource(d.url, 'video')})`
                      }}
                    ></div>
                    <img src={urlSource(d.url, 'video')} />
                    <div className="play">
                      <Play />
                    </div>
                  </div>
                  <div className="detail">
                    <label>{d.name}</label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <VideoModal preview={videoPreview} onCancel={() => setVideoPreview(undefined)} />
    </Modal>
  )
})

VoiceDetail.displayName = 'VoiceDetail'

export default memo(VoiceDetail)
